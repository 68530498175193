import axios from "axios"
import queryString from 'query-string'
import { getUserSession, clearStorageUser } from '@utils'

const api = axios.create({
  // baseURL: "https://api-hml.hubliberta.com.br"
  baseURL: process.env.REACT_APP_API_URL
})

api.interceptors.request.use((config) => {
  const query = queryString.parse(window.location.search)
  let token = query?.token || getUserSession()

  if (window.location.pathname.includes("/token/")) {
    const str = window.location.pathname
    token = str.split("/")[2]
    token = `token=${window.atob(token)}`
    const query = queryString.parse(token)
    token = query?.token
  }

  if (token && !config.params?.notSendToken) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
}, (error) => {
  const {status} = error.response
   if (status === 401 && window.location.pathname !== '/carrinho' && window.location.pathname !== '/pagamento/sucesso') {
     clearStorageUser()

    if (process.env.REACT_APP_ENV !== 'DEV') {
      const GLOBAL_URL = process.env.REACT_APP_GLOBAL_URL
      const BRASIL_URL = process.env.REACT_APP_SITE_URL
      const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL
      // const LIBERTA_URL = 'https://127.0.0.1:3000'

      const isGlobal = window.location.origin.includes(GLOBAL_URL)
      const pathname = window.location.pathname
      const isLiberta = window.location.origin.includes(LIBERTA_URL)
      const isSubject = pathname.includes("/lancamento/")
      const isMore = !!pathname.includes('/curso/') || !!pathname.includes('/interna/')

      if (isLiberta) {
        if (isSubject || isMore) {
          return
        }

        if (window.location.pathname === '/login') {
          return
        }
        const query = window.location.search

        if (!!query) {
          window.open(`${LIBERTA_URL}/login${query}`, '_self', 'noopener noreferrer')
          return
        }
        window.open(`${LIBERTA_URL}/login`, '_self', 'noopener noreferrer')
        return
      }

      if (isGlobal) {
        window.open(GLOBAL_URL, '_self', 'noopener noreferrer')
      }

      window.open(BRASIL_URL, '_self', 'noopener noreferrer')
    }
  }
  return Promise.reject(error)
})

api.interceptors.response.use((response) => {
  const token = getUserSession()
  if (token) {
    if (response.status === 401 && window.location.pathname !== '/carrinho' && window.location.pathname !== '/pagamento/sucesso') {
      clearStorageUser()

      if (process.env.REACT_APP_ENV !== 'DEV') {
        const GLOBAL_URL = process.env.REACT_APP_GLOBAL_URL
        const BRASIL_URL = process.env.REACT_APP_SITE_URL
        const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL
        // const LIBERTA_URL = 'https://127.0.0.1:3000'

        const isGlobal = window.location.origin.includes(GLOBAL_URL)
        const pathname = window.location.pathname
        const isLiberta = window.location.origin.includes(LIBERTA_URL)
        const isSubject = !!pathname.includes("/lancamento/")
        const isMore = !!pathname.includes('/curso/') || !!pathname.includes('/interna/')

        if (isLiberta) {
          if (isSubject || isMore) {
            return
          }
          if (window.location.pathname === '/login') {
            return
          }
          const query = window.location.search

          if (!!query) {
            window.open(`${LIBERTA_URL}/login${query}`, '_self', 'noopener noreferrer')
            return
          }

          window.open(`${LIBERTA_URL}/login`, '_self', 'noopener noreferrer')
          return
        }

        if (isGlobal) {
          window.open(GLOBAL_URL, '_self', 'noopener noreferrer')
        }

        window.open(BRASIL_URL, '_self', 'noopener noreferrer')
      }

      return response
    }

    return response
  } else if (response.status === 401 && window.location.pathname === '/carrinho') {
    return
  }

  return response
}, (error) => {
  const LIBERTA_URL = process.env.REACT_APP_LIBERTA_URL

  if (error.response.status === 401 && window.location.pathname !== '/carrinho' && window.location.pathname !== '/pagamento/sucesso') {
    clearStorageUser()

    if (process.env.REACT_APP_ENV !== 'DEV') {
      const GLOBAL_URL = process.env.REACT_APP_GLOBAL_URL
      const BRASIL_URL = process.env.REACT_APP_SITE_URL
      // const LIBERTA_URL = 'https://127.0.0.1:3000'

      const isGlobal = window.location.origin.includes(GLOBAL_URL)
      const pathname = window.location.pathname
      const isLiberta = window.location.origin.includes(LIBERTA_URL)
      const isSubject = pathname.includes("/lancamento/")
      const isMore = !!pathname.includes('/curso/') || !!pathname.includes('/interna/')

      if (isLiberta) {
        if (isSubject || isMore) {
          return
        }
        if (window.location.pathname === '/login') {
          return
        }

        const query = window.location.search

        if (!!query) {
          window.open(`${LIBERTA_URL}/login${query}`, '_self', 'noopener noreferrer')
          return
        }
        window.open(`${LIBERTA_URL}/login`, '_self', 'noopener noreferrer')
        return
      }

      if (isGlobal) {
        window.open(GLOBAL_URL, '_self', 'noopener noreferrer')
      }

      window.open(BRASIL_URL, '_self', 'noopener noreferrer')
    }
  }
  if (error.response.status === 401 && window.location.pathname === '/carrinho' && window.location.origin.includes(LIBERTA_URL)) {
    return
  }
  return Promise.reject(error)
})

export default api
