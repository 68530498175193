import api from './api'

export default {
  getDepartamentos: async () => {
    const response = await api
      .get('/api/select-department')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTags: async () => {
    const response = await api
      .get('/api/select-tag-option')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTagsExists: async () => {
    const response = await api
      .get('/api/select-tag-option-exists')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getProductPurchased: async () => {
    const response = await api
      .get('/api/select-product-purchased')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getProductInterest: async () => {
    const response = await api
      .get('/api/select-product-interest')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getCampaignInterest: async () => {
    const response = await api
      .get('/api/select-campaign-interest')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getFiliais: async () => {
    const response = await api
      .get('api/select-subsidiary')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRecordClassification: async (ficha_id) => {
    const response = await api
      .get('api/select-tab-classification', { params: { ficha_id } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getReasonRefusal: async (id) => {
    const response = await api
      .get(`api/select-reason-refusal/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRulerStatus: async (id) => {
    const response = await api
      .get('/api/select-ruler-status', { params: { id } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTypeActivity: async () => {
    const response = await api
      .get('api/select-type-activity')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getStatusActivity: async () => {
    const response = await api
      .get('api/select-status-activity')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getTabType: async () => {
    const response = await api
      .get('api/select-tab-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getClassificationType: async () => {
    const response = await api
      .get('api/select-classificacao')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getStatus: async () => {
    const response = await api
      .get('api/select-status')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLeads: async () => {
    const response = await api
      .get('api/select-type-lead')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getTeamLeaders: async () => {
    const response = await api
      .get('api/select-team-leader')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLeadDuplicated: async () => {
    const response = await api
      .get('api/select-action-lead-duplicated')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCodExportDiscador: async () => {
    const response = await api
      .get('api/select-sip-code')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCodTabulaRecebida: async () => {
    const response = await api
      .get('api/select-voll-tabulations')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getUf: async () => {
    const response = await api
      .get('api/select-uf')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLastOrigin: async () => {
    const response = await api
      .get('api/select-last-origin')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getOrigin: async () => {
    const response = await api
      .get('api/select-lead-entry-origin')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLeadCampaign: async () => {
    const response = await api
      .get('api/select-lead-campaign')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getSocialClass: async () => {
    const response = await api
      .get('api/select-social-class')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCampaign: async () => {
    const response = await api
      .get('api/select-campaign')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRoulette: async (typeLead) => {
    const response = await api
      .get(`api/select-roulletes/${typeLead}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRuler: async () => {
    const response = await api
      .get(`api/select-ruler`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getTeam: async () => {
    const response = await api
      .get(`api/select-team`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getOperator: async (params) => {
    const response = await api
      .get(`api/select-operator`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getBatchImport: async (params) => {
    const response = await api
      .get(`api/select-lote-importacao`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRouletteType: async () => {
    const response = await api
      .get(`api/select-roulette-type`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getAssessorByQueue: async (id) => {
    const response = await api
      .get(`api/select-assessor-queue/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLeadOrigin: async () => {
    const response = await api
      .get(`api/select-lead-origin`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getHolidays: async () => {
    const response = await api
      .get(`api/select-holiday`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getResponsability: async () => {
    const response = await api
      .get(`api/select-remove-responsability`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getAssessors: async (params) => {
    const response = await api
      .get(`api/select-acessor`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getHunters: async (params) => {
    const response = await api
      .get(`api/select-hunter`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getHsm: async (params) => {
    const response = await api
      .get(`/api/select-hsm`, { params })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getHsmMedia: async (params) => {
    const response = await api
      .get(`/api/select-hsm-media`, { params })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCampaingActive: async (params) => {
    const response = await api
      .get(`api/select-whatsapp-campaign`, {
        params
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getBaseInputType: async () => {
    const response = await api
      .get(`api/select-base-input-type`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getCardStatus: async () => {
    const response = await api
      .get('/api/select-status-ficha')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTab: async () => {
    const response = await api
      .get('/api/select-tab')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getQueue: async () => {
    const response = await api
      .get('/api/select-queue')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getHsmType: async (params) => {
    const response = await api.get(`api/select-hsm-type`, {
      params
    })

    return response
  },
  getAllHunters: async () => {
    const response = await api
      .get('/api/select-all-hunters')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getAllAcessors: async () => {
    const response = await api
      .get('/api/select-all-acessors')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getRecordrulerStatus: async (id) => {
    const response = await api
      .get(`/api/select-record-ruler-status/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getOrderOptions: async () => {
    const response = await api
      .get('/api/select-order-leads')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getType: async () => {
    const response = await api
      .get('/api/select-configuration-type-responsible-removal-service')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTypeOperetor: async () => {
    const response = await api
      .get('/api/select-operator-type')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTypeOrigin: async () => {
    const response = await api
      .get('/api/select-lead-entry-origin-lp')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getTypeOriginAll: async () => {
    const response = await api
      .get('/api/select-lead-entry-origin')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getResponsibleRemovalOptions: async () => {
    const response = await api
      .get('/api/select-responsible-removal-options')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getStatusBulkChange: async (rulerId) => {
    const response = await api
      .get(`/api/select-ruler-status-bulk-change/${rulerId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getCluster: async () => {
    const response = await api
      .get(`/api/select-cluster`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getStatusLeadLp: async () => {
    const response = await api
      .get(`/api/select-status-lead-lp`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getUtmObjetivo: async () => {
    const response = await api
      .get(`/api/select-utm-objetivo`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getTeamRuler: async () => {
    const response = await api
      .get(`api/select-team-rulers`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getResponsible: async () => {
    const response = await api
      .get(`api/select-responsible-removal-action`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLeadOriginClone: async () => {
    const response = await api
      .get(`/api/select-lead-origin-clone`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getStatusPaymentOptions: async () => {
    const response = await api
      .get(`/api/select-status-pagamento`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
  
}
